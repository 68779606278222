import './global.scss';
import React, { useContext, useEffect, useState } from 'react';
import {
  AppLayout,
  FourOhFour,
  Link,
  Route,
  Icon,
  Sidebar,
  SidebarItem,
  SidebarSection,
  Topnav,
  SkipToContent,
  SkipToContentItem,
  OfflineIndicator,
} from '@monash/portal-react';
import * as Sentry from '@sentry/browser';
import {
  AppLauncherPad,
  EncumbranceNotification,
  ErrorPageWrapper,
  MenuContext,
  ImpersonationContext,
  UserMenu,
  NotificationBadge,
  MaintenanceContext,
  ChatBotContext,
  GenericError,
} from '@monash/portal-frontend-common';
import PreviewPanel from './components/ui/preview-panel/PreviewPanel';
import Profile from './components/pages/profile/Profile';
import CourseProgress from './components/pages/course-progress/CourseProgress';
import { StudentContext } from './components/providers/StudentProvider';
import PageNotFoundWrapper from './components/ui/page-not-found-wrapper/PageNotFoundWrapper';
import { USER_TYPE } from './constants/user-type';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import c from './app.module.scss';

const App = () => {
  const { menu, handleMenu, closeMenu, size, menuRef, getMenuRef } =
    useContext(MenuContext);
  const [showSidebar, setShowSidebar] = useState(true);
  const [isOffline, setIsOffline] = useState(false);
  const { fullPageError } = useContext(StudentContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { avatar, profileData } = useContext(StudentContext);
  const { maintenanceData } = useContext(MaintenanceContext);
  const { ChatBotButton } = useContext(ChatBotContext);

  // Nav AX
  const paths = ['/', '/course-progress'];
  const [selectedPageIndex, setSelectedPageIndex] = useState(
    paths.indexOf(window.location.pathname)
  );

  useEffect(() => {
    Sentry.setUser({ id: currentUser?.uid });

    window.addEventListener('offline', () => {
      setIsOffline(true);
    });
    window.addEventListener('online', () => {
      setIsOffline(false);
    });
  }, []);

  // get user type for app launcher
  const userType = currentUser?.is?.MonashCollege
    ? USER_TYPE.COLLEGE
    : USER_TYPE.GENERAL;

  const fullPageMaintenanceType = 'SMSPartialMaintenance';
  const maintenance = maintenanceData?.[fullPageMaintenanceType]?.enabled
    ? maintenanceData?.[fullPageMaintenanceType]
    : {};

  return (
    <SentryErrorBoundary fallback={<GenericError />}>
      <ErrorPageWrapper
        error={fullPageError}
        userType={userType}
        navTitle="Profile"
        userMenu={<UserMenu avatarData={avatar} profileData={profileData} />}
        title="Whoops, something went wrong ..."
        message="It looks like the page you're trying to reach is on a study break. But we're working on getting it back and running as soon as we can. Please try again later."
        inMaintenance={maintenance}
      >
        <SkipToContent>
          <SkipToContentItem text="Top navigation" skipTo="#topNav" />
          <SkipToContentItem text="Sidebar" skipTo="#sidebar" />
          <SkipToContentItem text="Content" skipTo="#contentContainer" />
        </SkipToContent>

        <AppLayout
          topnav={
            <Topnav
              title="Profile"
              onMenu={handleMenu}
              size={size}
              getMenuRef={getMenuRef}
            >
              <div>
                <NotificationBadge />
                <AppLauncherPad type={userType} />
                <ChatBotButton />
                <UserMenu avatarData={avatar} profileData={profileData} />
              </div>
            </Topnav>
          }
          sidebar={
            <Sidebar
              title="Profile"
              ifMenu={menu}
              toggleMenu={handleMenu}
              closeMenu={closeMenu}
              size={size}
              menuRef={menuRef}
              selected={selectedPageIndex}
              onChange={setSelectedPageIndex}
            >
              <SidebarSection title="Tabs">
                <SidebarItem
                  id="tab-home"
                  linkTo="/"
                  text="Profile"
                  icon={<Icon.Home />}
                  onMenu={closeMenu}
                />
                <SidebarItem
                  id="tab-course-progress"
                  linkTo="/course-progress"
                  text="Course progress"
                  icon={<Icon.ListCheck />}
                  onMenu={closeMenu}
                />
              </SidebarSection>
            </Sidebar>
          }
          content={
            <>
              {currentUser.hasEncumbrances && <EncumbranceNotification />}

              <Route to="/" exact={true}>
                <SentryErrorBoundary fallback={<GenericError />}>
                  <Profile />
                </SentryErrorBoundary>
              </Route>

              <Route to="/course-progress" exact={true}>
                <SentryErrorBoundary fallback={<GenericError />}>
                  <CourseProgress />
                </SentryErrorBoundary>
              </Route>

              <Route to="/preview">
                <SentryErrorBoundary fallback={<GenericError />}>
                  <Profile />
                </SentryErrorBoundary>
              </Route>

              <PreviewPanel />

              <FourOhFour>
                <PageNotFoundWrapper
                  setShowSidebar={setShowSidebar}
                  action={<Link to="/">Go to Profile</Link>}
                />
              </FourOhFour>
            </>
          }
          showSidebar={showSidebar}
        />

        {isOffline && <OfflineIndicator className={c.offlineIndicator} />}
      </ErrorPageWrapper>
    </SentryErrorBoundary>
  );
};

export default App;
