import { useContext, useEffect, useState } from 'react';
import { Dropdown, Alert, InlineExternalLink } from '@monash/portal-react';
import {
  APIContext,
  ImpersonationContext,
  AccessibilityContext,
} from '@monash/portal-frontend-common';
import { StudentContext } from '../../../../providers/StudentProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import InlineForm from '../in-line-form/InlineForm';
import c from './pronoun.module.scss';

const focusOnPronounDropdownTrigger = (timeout = 0) => {
  setTimeout(() => {
    // AX: autofocus onto pronouns Dropdown trigger
    const pronounsDropdownTrigger = document.querySelector(
      'button[role="combobox"][aria-controls="pronoun list"][aria-labelledby="pronouns"]'
    );
    pronounsDropdownTrigger !== null && pronounsDropdownTrigger.focus();
  }, timeout);
};

const Pronoun = ({ inEdit, setInEdit }) => {
  const { getPronounsOptions, updatePronouns } = useContext(APIContext);
  const { profileData, setProfileData } = useContext(StudentContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const { addSnackbar } = useSnackbar();

  // Pronouns
  const [selectedPronouns, setSelectedPronouns] = useState(
    profileData?.pronouns
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatePronounsError, setUpdatePronounsError] = useState(null);

  // Pronouns dropdown options
  const [pronounsOptions, setPronounsOptions] = useState([
    {
      id: selectedPronouns,
      text: selectedPronouns,
      value: selectedPronouns,
    },
  ]);
  const [loadingPronounsOptions, setLoadingPronounsOptions] = useState(true);
  const [pronounsOptionsError, setPronounsOptionsError] = useState(false);

  // Fetch pronouns options
  useEffect(() => {
    setLoadingPronounsOptions(true);
    setPronounsOptionsError(false);

    if (inEdit && currentUser) {
      getPronounsOptions()
        .then((r) => {
          setLoadingPronounsOptions(false);
          setPronounsOptions(
            r.data.map((pronouns) => {
              return {
                id: pronouns.value,
                text: pronouns.label,
                value: pronouns.value,
              };
            })
          );
          focusOnPronounDropdownTrigger();
        })
        .catch((error) => {
          setPronounsOptionsError(error);
          setLoadingPronounsOptions(false);
        });
    }
  }, [inEdit, currentUser]);

  // Update pronouns
  const submitHandler = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setUpdatePronounsError(null);
    updatePronouns({
      pronouns: selectedPronouns,
    })
      .then((r) => {
        setIsSubmitting(false);
        setProfileData((f) => {
          return { ...f, pronouns: r.pronouns };
        });
        setInEdit(false);

        resetAppLiveMsgs();
        addSnackbar({
          message: 'Your pronouns have been updated.',
          type: 'success',
        });
      })
      .catch((error) => {
        setIsSubmitting(false);
        setUpdatePronounsError(error);
      });
  };

  return (
    <InlineForm
      submitHandler={submitHandler}
      isSubmitEnabled={selectedPronouns !== profileData?.pronouns}
      setInEdit={setInEdit}
      isSubmitting={isSubmitting}
      isLoading={loadingPronounsOptions}
    >
      <div className={c.pronoun}>
        <label id="pronouns">Pronouns</label>
        <Dropdown
          listId="pronoun list"
          ariaLabelledby="pronouns"
          value={selectedPronouns}
          onChange={(e) => setSelectedPronouns(e)}
          disabled={isSubmitting || pronounsOptionsError}
          data={pronounsOptions}
        />
        {pronounsOptionsError && (
          <Alert type="error">
            Sorry, the pronouns list is currently <strong>not available</strong>
            , please try again later.
          </Alert>
        )}
        <p>
          If you can’t find your pronouns in the list, you can nominate your own
          by submitting our{' '}
          <InlineExternalLink
            link="https://forms.monash.edu/personal-details"
            text="Change Your Personal Details form"
          />
        </p>
        {updatePronounsError && (
          <Alert type="error">
            Sorry, we're having <strong>trouble saving your pronouns</strong>{' '}
            right now – come back and try again later.
          </Alert>
        )}
      </div>
    </InlineForm>
  );
};

export default Pronoun;
